import React, { useEffect, useState } from "react";
import { CarPartDetails, PartNumberSave } from "../Services/apiServices";
import { json, useNavigate, useParams } from "react-router-dom";
import Loader from "../utlis/Loader";
import Car1 from "../../src/image/lodear/Car1.gif";
import Car2 from "../../src/image/lodear/Car2.gif";
import Car3 from "../../src/image/lodear/Car3.gif";
import Car4 from "../../src/image/lodear/Car4.gif";
import Car5 from "../../src/image/lodear/Car5.gif";
import Car6 from "../../src/image/lodear/Car6.gif";
import Car7 from "../../src/image/lodear/Car7.gif";
import Car8 from "../../src/image/lodear/Car8.gif";
import Car9 from "../../src/image/lodear/Car9.gif";
import { motion, useAnimation } from "framer-motion";
import Breadcrumb from "../utlis/Breadcrumb";

const CarPartsDetails = () => {
  const [positionArr, setPositionArry] = useState(null);
  const [figArr, setFigArr] = useState([]);
  const [partsDetails, setPartsDetails] = useState([]);
  const { models, submodels, parts, subparts, partdetails } = useParams();
  const [loader, setLoader] = useState(true);
  const [partNumber, setPartNumber] = useState('');
  const [partLoader, setPartLoader] = useState(false);
  const [updatedParts, setUpdatedParts] = useState({});

  const [hoveredFig, setHoveredFig] = useState(null);

  const handleMouseEnter = (figNumber) => {
    setHoveredFig(figNumber);
  };

  const handleMouseLeave = () => {
    setHoveredFig(null);
  };

  const navigate = useNavigate();
  const controls = useAnimation();

  // useEffect to start the animation only after the component has mounted
  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);


  // const savePart = (id,e) => {
  //   let data = {
  //     id,
  //     PN: partNumber
  //   }
  //   // console.log("dd", data);
  //   setPartLoader(true)
  //   PartNumberSave(data).then((res) => {
  //     if (res.success) {
  //       // console.log("part", data);
  //       setPartLoader(false);
  //     }
  //   }).catch(() => {
  //     setPartLoader(false);
  //   });
  // }


  const savePart = (id) => {
    return true;
    let data = {
      id,
      PN: partNumber
    };
    setPartLoader(true);
    PartNumberSave(data)
      .then((res) => {
        if (res.success) {
          setUpdatedParts((prev) => ({
            ...prev,
            [id]: partNumber
          }));
          // console.warn("last",res);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        setPartLoader(false);
      })
      .catch(() => {
        setPartLoader(false);
      });
  };


  if (partLoader) {
    <p>Loader</p>
  }

  // Trigger animation when the component is in view
  const handleInView = (inView) => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  };
  useEffect(() => {
    let data = {
      company_id: models,
      model_id: submodels,
      sub_model_id: parts,
      part_id: subparts,
      sub_part_id: partdetails,
    };
    CarPartDetails(data).then((res) => {
      console.log(res);
      if (res?.success) {
        setPartsDetails(res?.data);
        setLoader(false);
        setPositionArry(JSON.parse(res?.data?.subparts?.positions));

        let figNoSet = new Set(figArr);
        if (res?.data?.data.length > 0) {
          res?.data?.data.forEach(element => {
            if (element.FigNo && element.FigNo != "") {
              figNoSet.add(element.FigNo);
            }
          });
          setFigArr(Array.from(figNoSet));
        }

      } else {
        navigate("/404");
      }
    });
  }, []);
  const breadcrumbs = [
    { label: "Home", url: "/" },
    {
      label: partsDetails?.subparts?.part?.sub_model?.model?.company?.name,
      url: `/${partsDetails?.subparts?.part?.sub_model?.model?.company.id}`,
    },
    {
      label: partsDetails?.subparts?.part?.sub_model?.model?.name,
      url: `/${partsDetails?.subparts?.part?.sub_model?.model?.company.id}/${partsDetails?.subparts?.part?.sub_model?.model?.id}`,
    },
    {
      label: partsDetails?.subparts?.part?.sub_model?.name,
      url: `/${partsDetails?.subparts?.part?.sub_model.model?.company.id}/${partsDetails?.subparts?.part?.sub_model?.model?.id}/${partsDetails?.subparts?.part?.sub_model?.id}`,
    },
    {
      label: partsDetails?.subparts?.part?.name,
      url: `/${partsDetails?.subparts?.part?.sub_model.model?.company.id}/${partsDetails?.subparts?.part?.sub_model?.model?.id}/${partsDetails?.subparts?.part?.sub_model?.id}/${partsDetails?.subparts?.part?.id}`,
    },
    { label: partsDetails?.subparts?.name, url: "" },
  ];

  const scrollToRow = (figNumber) => {
    const element = document.getElementById(`row-${figNumber}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };


  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="container mt-4">
          <Breadcrumb items={breadcrumbs} />
          <div className="d-flex align-items-center justify-content-center gap-5 flex-wrap">
            <div className="">
              <img
                src={partsDetails?.subparts?.part?.sub_model?.model?.image}
                style={{ width: "200px" }}
                alt=""
              />
            </div>
            <div className="">
              <h4>
                {partsDetails?.subparts?.name}
                {partsDetails?.subparts?.part?.sub_model?.model?.company?.name}
                {partsDetails?.subparts?.part?.sub_model?.model?.name}
                {partsDetails?.subparts?.part?.sub_model?.name}
              </h4>
              <div style={{ fontSize: "12px" }}>
                <span>
                  Region : {partsDetails?.subparts?.part?.sub_model?.region}
                </span>{" "}
                <br />
                <span>
                  Production year :{" "}
                  {partsDetails?.subparts?.part?.sub_model?.production_year}
                </span>{" "}
                <br />
                <span>
                  Engine type :{" "}
                  {partsDetails?.subparts?.part?.sub_model?.engine}
                </span>{" "}
                <br />
                <span>Body type : SEDAN</span>
              </div>
            </div>
          </div>
          <hr />
          <div className="row m-0 p-0">
            <div className="col-md-6 text-center" >
              {/* <div style={{ position: "relative" }}> */}
              <div style={{ position: "sticky", top: "200px" }}>
                <img
                  src={partsDetails?.subparts?.img_name ? partsDetails?.subparts?.img_name : partsDetails?.subparts?.image}
                  style={{ width: "100%" }}
                  // className="img-fluid"
                  alt=""
                />
                {positionArr?.map((e, i) => {
                  const isFigNoInArray = figArr.includes(e.FigNo);
                  const left = e.left.replace('%', '');
                  const top = e.top.replace('%', '');
                  return (
                    isFigNoInArray &&
                    <a
                      key={i}
                      className={`fig-number ${hoveredFig === e.FigNo ? 'hovered' : ''}`}
                      style={{
                        position: 'absolute',
                        left: left + "%",
                        top: top + "%",
                        background: 'yellow',
                        borderRadius: '3px',
                        cursor: 'pointer'
                      }}
                      onMouseEnter={() => handleMouseEnter(e.FigNo)}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => scrollToRow(e.FigNo)}
                    >
                      {e.FigNo}
                      {/* <span>{console.log(JSON.parse(e.positions))}</span> */}
                    </a>
                  )
                })}
              </div>
            </div>
            <div className="col-md-6">
              {partLoader ?
                <>
                  {/* <h3 style={{display:"flex",alignItems:"center",justifyContent:"center" ,width:"100%"}}>loader.....</h3> */}
                  <Loader />
                  {/* <img src={Car8} alt="loader" /> */}
                </>
                :
                <>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={controls}
                    transition={{ duration: 0.5 }}
                    style={{ textDecoration: "none" }}
                  >
                    <motion.div
                      className="pro_card"
                      onViewportEnter={(viewportEntry) =>
                        handleInView(viewportEntry.target)
                      }
                    >
                      <div className="table-responsive">
                        <table className="table table-bordered" >
                          <thead>
                            <tr className="text-center table_heder">
                              <th>ID</th>
                              <th>Fig. NO.</th>
                              <th>Part NO.</th>
                              {/* <th>New Part Save.</th> */}
                              {/* <th>Edit.</th> */}
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody style={{ fontSize: "12px" }}>
                            {partsDetails?.data?.map((e, index) => {
                              // setFigArr(...figArr, e.FigNo)
                              return (
                                <tr
                                  key={index}
                                  className={`text-center item_1 ${hoveredFig === e.FigNo ? 'hovered' : ''}`}
                                  onMouseEnter={() => handleMouseEnter(e.FigNo)}
                                  onMouseLeave={handleMouseLeave}
                                  id={`row-${e.FigNo}`}
                                >
                                  <td>{e?.id}</td>
                                  <td>{e?.FigNo ? e?.FigNo : "-"}</td>
                                  <td className="breadcrumb-link">
                                    {e?.PartNo} <br />
                                    {e?.PN !== null && e?.slug !== null ?
                                      <a
                                        style={{ color: "red" }}
                                        className="breadcrumb-link-label"
                                        // href={`${e?.Link}/?back_url_pn=https://mechx.hypehy.com/boodmo.com/search/{pn}/`}
                                        // href={`${e?.Link}/?back_url_pn=https://tofunmech.com/search/{pn}/`}
                                        // href={`http://localhost:3000/search/${e.PN}`}
                                        href={`https://tofunmech.com/search/${e.PN}`}
                                        target="_blank"
                                      >
                                        Buy
                                      </a>
                                      :
                                      ""
                                    }
                                  </td>
                                  {/* <td style={{ minWidth: "200px" }}>
                                    {updatedParts[e.id] || e.PN ? (
                                      <p>{updatedParts[e.id] || e.PN}</p>
                                    )
                                      :
                                      <>
                                        <div className="input-group mb-3">
                                          <input type="text" className="form-control" placeholder="" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(event) => setPartNumber(event.target.value)} />
                                          <button className="btn btn-success" type="button" id="button-addon2" onClick={() => savePart(e.id)}>Save</button>
                                        </div>
                                      </>
                                    }
                                  </td> */}
                                  {/* <td>
                                  <div className="input-group mb-3">
                                          <input type="text" className="form-control" placeholder="" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(event) => setPartNumber(event.target.value)} />
                                          <button className="btn btn-info" type="button" id="button-addon2" onClick={() => savePart(e.id)}>Edit</button>
                                        </div>
                                  </td> */}
                                  <td>{e?.Description}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </motion.div>
                  </motion.div>
                </>
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CarPartsDetails;
