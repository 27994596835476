import { Route, Routes } from "react-router-dom";
import CarComapnyList from "./MainComponents/CarComapnyList";
import CarModelList from "./MainComponents/CarModelList";
import CarModification from "./MainComponents/CarModification";
import CarSparepartList from "./MainComponents/CarSparepartList";
import CarSubParts from "./MainComponents/CarSubParts";
import CarPartsDetails from "./MainComponents/CarPartsDetails";
import Page404 from "./utlis/Page404";
import "./App.css";
import Header from "./MainComponents/Header";
import CarModalHeader from "./MainComponents/CarModalHeader";
import TestStyleImg from "./test/TestStyleImg";

function App() {
  return (
    <>
      {/* <TestStyleImg/> */}

      <Header />
      <Routes>
        <Route path="/" element={<CarComapnyList />} />
        <Route path="/:models" element={<CarModelList />} />
        <Route path="/:models/:submodels" element={<CarModification />} />
        <Route path="/:models/:submodels/:parts" element={<CarSparepartList />} />
        <Route path="/:models/:submodels/:parts/:subparts" element={<CarSubParts />} />
        <Route path="/:models/:submodels/:parts/:subparts/:partdetails" element={<CarPartsDetails />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="/ff" element={<CarModalHeader />} />
        <Route path="/page" element={<CarModalHeader />} />
        <Route path="/test" element={<TestStyleImg />} />
      </Routes>

      {/* <button className="button_fgh">Btn..</button> */}
    </>
  );
}

export default App;
