import React, { useState } from 'react'

function TestStyleImg() {
    const Data = [
        {
            "FigNo": "75",
            "top": "1.7",
            "left": "15.0"
        },
        {
            "FigNo": "4",
            "top": "6.3",
            "left": "75.4"
        },
        {
            "FigNo": "80",
            "top": "11.3",
            "left": "39.0"
        },
        {
            "FigNo": "80",
            "top": "18.9",
            "left": "40.8"
        },
        {
            "FigNo": "56",
            "top": "23.9",
            "left": "38.1"
        },
        {
            "FigNo": "78",
            "top": "28.1",
            "left": "39.9"
        },
        {
            "FigNo": "59",
            "top": "30.5",
            "left": "19.9"
        },
        {
            "FigNo": "8",
            "top": "33.2",
            "left": "13.9"
        },
        {
            "FigNo": "12",
            "top": "33.8",
            "left": "69.9"
        },
        {
            "FigNo": "63",
            "top": "37.0",
            "left": "50.9"
        },
        {
            "FigNo": "55",
            "top": "40.5",
            "left": "68.8"
        },
        {
            "FigNo": "14",
            "top": "41.6",
            "left": "81.9"
        },
        {
            "FigNo": "18",
            "top": "43.2",
            "left": "16.9"
        },
        {
            "FigNo": "70",
            "top": "44.3",
            "left": "48.4"
        },
        {
            "FigNo": "42",
            "top": "45.3",
            "left": "74.4"
        },
        {
            "FigNo": "5",
            "top": "46.6",
            "left": "73.3"
        },
        {
            "FigNo": "35",
            "top": "48.6",
            "left": "26.3"
        },
        {
            "FigNo": "68",
            "top": "51.2",
            "left": "25.9"
        },
        {
            "FigNo": "16",
            "top": "51.5",
            "left": "13.0"
        },
        {
            "FigNo": "38",
            "top": "54.3",
            "left": "22.6"
        },
        {
            "FigNo": "57",
            "top": "56.2",
            "left": "60.0"
        },
        {
            "FigNo": "7",
            "top": "57.6",
            "left": "3.7"
        },
        {
            "FigNo": "71",
            "top": "62.8",
            "left": "11.0"
        },
        {
            "FigNo": "26",
            "top": "63.5",
            "left": "72.3"
        },
        {
            "FigNo": "68",
            "top": "63.6",
            "left": "78.3"
        },
        {
            "FigNo": "13",
            "top": "64.1",
            "left": "33.1"
        },
        {
            "FigNo": "59",
            "top": "64.7",
            "left": "92.4"
        },
        {
            "FigNo": "28",
            "top": "65.3",
            "left": "66.3"
        },
        {
            "FigNo": "55",
            "top": "65.4",
            "left": "58.6"
        },
        {
            "FigNo": "12",
            "top": "66.7",
            "left": "10.1"
        },
        {
            "FigNo": "32",
            "top": "70.8",
            "left": "53.7"
        },
        {
            "FigNo": "25",
            "top": "74.3",
            "left": "67.6"
        },
        {
            "FigNo": "33",
            "top": "75.3",
            "left": "43.4"
        },
        {
            "FigNo": "51",
            "top": "75.5",
            "left": "65.0"
        },
        {
            "FigNo": "26",
            "top": "75.8",
            "left": "68.6"
        },
        {
            "FigNo": "21",
            "top": "75.9",
            "left": "48.9"
        },
        {
            "FigNo": "21",
            "top": "77.1",
            "left": "65.9"
        },
        {
            "FigNo": "20",
            "top": "82.2",
            "left": "62.0"
        },
        {
            "FigNo": "31",
            "top": "85.6",
            "left": "42.4"
        },
        {
            "FigNo": "91",
            "top": "87.4",
            "left": "86.0"
        },
        {
            "FigNo": "92",
            "top": "90.1",
            "left": "81.3"
        },
        {
            "FigNo": "50",
            "top": "92.5",
            "left": "16.4"
        }
        // {
        //     'FigNo': '6',
        //     'left': '75.3623%',
        //     'top': '6.44024%'
        // },
        // {
        //     'FigNo': '7',
        //     'left': '83.1884%',
        //     'top': '11.8897%'
        // },
        // {
        //     'FigNo': '15',
        //     'left': '75.5073%',
        //     'top': '13.0786%'
        // },
        // {
        //     'FigNo': '8',
        //     'left': '62.6087%',
        //     'top': '14.1685%'
        // },
        // {
        //     'FigNo': '10',
        //     'left': '61.7391%',
        //     'top': '10.9979%'
        // },
        // {
        //     'FigNo': '1',
        //     'left': '52.8986%',
        //     'top': '13.475%'
        // },
        // {
        //     'FigNo': '84',
        //     'left': '44.9275%',
        //     'top': '6.6384%'
        // },
        // {
        //     'FigNo': '79',
        //     'left': '46.6667%',
        //     'top': '14.8621%'
        // },
        // {
        //     'FigNo': '85',
        //     'left': '41.8841%',
        //     'top': '13.1777%'
        // },
        // {
        //     'FigNo': '80',
        //     'left': '38.9855%',
        //     'top': '11.8897%'
        // },
        // {
        //     'FigNo': '88',
        //     'left': '36.8116%',
        //     'top': '15.5556%'
        // },
        // {
        //     'FigNo': '83',
        //     'left': '34.058%',
        //     'top': '5.35035%'
        // },
        // {
        //     'FigNo': '87',
        //     'left': '28.9855%',
        //     'top': '7.6292%'
        // },
        // {
        //     'FigNo': '81',
        //     'left': '16.087%',
        //     'top': '12.5832%'
        // },
        // {
        //     'FigNo': '82',
        //     'left': '30.2899%',
        //     'top': '24.572%'
        // },
        // {
        //     'FigNo': '80',
        //     'left': '40.8696%',
        //     'top': '20.0143%'
        // },
        // {
        //     'FigNo': '17',
        //     'left': '43.6232%',
        //     'top': '20.8069%'
        // },
        // {
        //     'FigNo': '56',
        //     'left': '38.6957%',
        //     'top': '24.2747%'
        // },
        // {
        //     'FigNo': '5',
        //     'left': '46.2319%',
        //     'top': '26.4545%'
        // },
        // {
        //     'FigNo': '11',
        //     'left': '76.3768%',
        //     'top': '31.2104%'
        // },
        // {
        //     'FigNo': '12',
        //     'left': '70%',
        //     'top': '33.8855%'
        // },
        // {
        //     'FigNo': '4',
        //     'left': '66.2319%',
        //     'top': '37.0561%'
        // },
        // {
        //     'FigNo': '9',
        //     'left': '65.5073%',
        //     'top': '33.9846%'
        // },
        // {
        //     'FigNo': '41',
        //     'left': '69.7101%',
        //     'top': '38.5423%'
        // },
        // {
        //     'FigNo': '44',
        //     'left': '74.3478%',
        //     'top': '42.7037%'
        // },
        // {
        //     'FigNo': '14',
        //     'left': '82.3188%',
        //     'top': '41.4157%'
        // },
        // {
        //     'FigNo': '42',
        //     'left': '74.2029%',
        //     'top': '45.478%'
        // },
        // {
        //     'FigNo': '43',
        //     'left': '67.1015%',
        //     'top': '47.6577%'
        // },
        // {
        //     'FigNo': '3',
        //     'left': '62.6087%',
        //     'top': '40.8212%'
        // },
        // {
        //     'FigNo': '16',
        //     'left': '54.4928%',
        //     'top': '33.3901%'
        // },
        // {
        //     'FigNo': '78',
        //     'left': '40%',
        //     'top': '28.238%'
        // },
        // {
        //     'FigNo': '71',
        //     'left': '47.6812%',
        //     'top': '35.2727%'
        // },
        // {
        //     'FigNo': '63',
        //     'left': '51.3043%',
        //     'top': '37.0561%'
        // },
        // {
        //     'FigNo': '57',
        //     'left': '28.1159%',
        //     'top': '28.238%'
        // },
        // {
        //     'FigNo': '58',
        //     'left': '22.8986%',
        //     'top': '29.526%'
        // },
        // {
        //     'FigNo': '59',
        //     'left': '20.2899%',
        //     'top': '30.4177%'
        // },
        // {
        //     'FigNo': '60',
        //     'left': '13.6232%',
        //     'top': '33.192%'
        // },
        // {
        //     'FigNo': '58',
        //     'left': '15.7971%',
        //     'top': '36.2635%'
        // },
        // {
        //     'FigNo': '70',
        //     'left': '33.6232%',
        //     'top': '37.1552%'
        // },
        // {
        //     'FigNo': '67',
        //     'left': '35.3623%',
        //     'top': '39.335%'
        // },
        // {
        //     'FigNo': '70',
        //     'left': '48.4058%',
        //     'top': '44.3881%'
        // },
        // {
        //     'FigNo': '65',
        //     'left': '50.2899%',
        //     'top': '48.2522%'
        // },
        // {
        //     'FigNo': '65',
        //     'left': '60%',
        //     'top': '48.5495%'
        // },
        // {
        //     'FigNo': '64',
        //     'left': '53.4783%',
        //     'top': '53.4044%'
        // },
        // {
        //     'FigNo': '51',
        //     'left': '53.6232%',
        //     'top': '58.9529%'
        // },
        // {
        //     'FigNo': '40',
        //     'left': '20%',
        //     'top': '42.0101%'
        // },
        // {
        //     'FigNo': '36',
        //     'left': '16.6667%',
        //     'top': '43.4964%'
        // },
        // {
        //     'FigNo': '39',
        //     'left': '11.8841%',
        //     'top': '45.6761%'
        // },
        // {
        //     'FigNo': '38',
        //     'left': '10.4348%',
        //     'top': '48.8467%'
        // },
        // {
        //     'FigNo': '76',
        //     'left': '13.3333%',
        //     'top': '51.8191%'
        // },
        // {
        //     'FigNo': '72',
        //     'left': '18.5507%',
        //     'top': '54.9897%'
        // },
        // {
        //     'FigNo': '35',
        //     'left': '27.1014%',
        //     'top': '48.7476%'
        // },
        // {
        //     'FigNo': '68',
        //     'left': '26.087%',
        //     'top': '51.3237%'
        // },
        // {
        //     'FigNo': '38',
        //     'left': '22.4638%',
        //     'top': '53.8998%'
        // },
        // {
        //     'FigNo': '37',
        //     'left': '33.6232%',
        //     'top': '57.4667%'
        // },
        // {
        //     'FigNo': '75',
        //     'left': '20.5797%',
        //     'top': '59.9437%'
        // },
        // {
        //     'FigNo': '74',
        //     'left': '17.6812%',
        //     'top': '60.8354%'
        // },
        // {
        //     'FigNo': '74',
        //     'left': '11.7391%',
        //     'top': '62.9161%'
        // },
        // {
        //     'FigNo': '73',
        //     'left': '8.26087%',
        //     'top': '64.5014%'
        // },
        // {
        //     'FigNo': '18',
        //     'left': '9.85507%',
        //     'top': '66.8794%'
        // },
        // {
        //     'FigNo': '13',
        //     'left': '33.0435%',
        //     'top': '64.4024%'
        // },
        // {
        //     'FigNo': '76',
        //     'left': '7.10145%',
        //     'top': '58.8538%'
        // },
        // {
        //     'FigNo': '77',
        //     'left': '3.91304%',
        //     'top': '57.4667%'
        // },
        // {
        //     'FigNo': '54',
        //     'left': '55.6522%',
        //     'top': '56.4759%'
        // },
        // {
        //     'FigNo': '52',
        //     'left': '60%',
        //     'top': '56.1787%'
        // },
        // {
        //     'FigNo': '53',
        //     'left': '61.1594%',
        //     'top': '61.529%'
        // },
        // {
        //     'FigNo': '55',
        //     'left': '58.4058%',
        //     'top': '65.6904%'
        // },
        // {
        //     'FigNo': '32',
        //     'left': '53.7681%',
        //     'top': '70.7435%'
        // },
        // {
        //     'FigNo': '2',
        //     'left': '57.8261%',
        //     'top': '75.103%'
        // },
        // {
        //     'FigNo': '33',
        //     'left': '43.6232%',
        //     'top': '75.5985%'
        // },
        // {
        //     'FigNo': '26',
        //     'left': '46.3768%',
        //     'top': '77.481%'
        // },
        // {
        //     'FigNo': '27',
        //     'left': '50%',
        //     'top': '76.0939%'
        // },
        // {
        //     'FigNo': '34',
        //     'left': '43.0435%',
        //     'top': '85.3083%'
        // },
        // {
        //     'FigNo': '49',
        //     'left': '26.8116%',
        //     'top': '89.5688%'
        // },
        // {
        //     'FigNo': '1',
        //     'left': '25.6522%',
        //     'top': '87.8844%'
        // },
        // {
        //     'FigNo': '45',
        //     'left': '25.942%',
        //     'top': '84.6148%'
        // },
        // {
        //     'FigNo': '47',
        //     'left': '27.2464%',
        //     'top': '82.3359%'
        // },
        // {
        //     'FigNo': '50',
        //     'left': '16.087%',
        //     'top': '93.0366%'
        // },
        // {
        //     'FigNo': '46',
        //     'left': '8.84058%',
        //     'top': '85.2093%'
        // },
        // {
        //     'FigNo': '48',
        //     'left': '6.52174%',
        //     'top': '81.147%'
        // },
        // {
        //     'FigNo': '89',
        //     'left': '92.6087%',
        //     'top': '64.6996%'
        // },
        // {
        //     'FigNo': '66',
        //     'left': '78.6957%',
        //     'top': '64.006%'
        // },
        // {
        //     'FigNo': '26',
        //     'left': '72.3188%',
        //     'top': '63.7088%'
        // },
        // {
        //     'FigNo': '27',
        //     'left': '69.2754%',
        //     'top': '64.3033%'
        // },
        // {
        //     'FigNo': '28',
        //     'left': '65.942%',
        //     'top': '65.5913%'
        // },
        // {
        //     'FigNo': '29',
        //     'left': '73.6232%',
        //     'top': '69.5545%'
        // },
        // {
        //     'FigNo': '90',
        //     'left': '83.0435%',
        //     'top': '74.5086%'
        // },
        // {
        //     'FigNo': '91',
        //     'left': '85.7971%',
        //     'top': '87.389%'
        // },
        // {
        //     'FigNo': '92',
        //     'left': '81.8841%',
        //     'top': '90.1633%'
        // },
        // {
        //     'FigNo': '19',
        //     'left': '76.6667%',
        //     'top': '86.0019%'
        // },
        // {
        //     'FigNo': '30',
        //     'left': '79.1304%',
        //     'top': '80.6516%'
        // },
        // {
        //     'FigNo': '31',
        //     'left': '79.5652%',
        //     'top': '77.7782%'
        // },
        // {
        //     'FigNo': '22',
        //     'left': '75.942%',
        //     'top': '75.6975%'
        // },
        // {
        //     'FigNo': '21',
        //     'left': '73.4783%',
        //     'top': '74.3104%'
        // },
        // {
        //     'FigNo': '24',
        //     'left': '65.3623%',
        //     'top': '75.5985%'
        // },
        // {
        //     'FigNo': '25',
        //     'left': '68.1159%',
        //     'top': '74.4095%'
        // },
        // {
        //     'FigNo': '26',
        //     'left': '69.4203%',
        //     'top': '76.3911%'
        // },
        // {
        //     'FigNo': '27',
        //     'left': '66.087%',
        //     'top': '77.3819%'
        // },
        // {
        //     'FigNo': '22',
        //     'left': '64.9275%',
        //     'top': '83.5249%'
        // },
        // {
        //     'FigNo': '20',
        //     'left': '62.3188%',
        //     'top': '82.2369%'
        // }
        // {
        //     "figNumber": 1,
        //     "label": "water hose with quick-coupling piece",
        //     "left": "36.3145%",
        //     "top": "6.72651%",
        //     "part": "0588jjo850",
        //     "decription": "this is 1st description",
        //     "remark": "this 1st remark"

        // },
        // {
        //     "figNumber": 1,
        //     "label": "water hose with quick-coupling piece",
        //     "left": "51.3145%",
        //     "top": "7.72651%",
        //     "part":"0588jjo850",
        //     "decription":"this is 1st description",
        //     "remark" :"this 1st remark"

        // },
        // {
        //     "figNumber": 2,
        //     "label": "quick acting coupling",
        //     "left": "53.613%",
        //     "top": "54.53%",
        //     "part": "1285jo8558",
        //     "decription": "this is 2nd description",
        //     "remark": "this 2nd remark"
        // },
        // {
        //     "figNumber": 3,
        //     "label": "other coupling",
        //     "left": "55.8145%",
        //     "top": "11.62651%",
        //     "part":"2485jo8598",
        //     "decription":"this is 3rd description",
        //     "remark" :"this 3rd remark"
        // }

    ]
    const [hoveredFig, setHoveredFig] = useState(null);

    const handleMouseEnter = (figNumber) => {
        setHoveredFig(figNumber);
    };

    const handleMouseLeave = () => {
        setHoveredFig(null);
    };
    console.log(Data);

    return (
        <>
            <div className="row">
                <div className="col-md-12 header">
                    <img src="https://i.oriparts.net/auto_model/0/00/00/00/11/69.png" />
                    <h1>1 set brake discs with brake pads (front and rear)
                        for AUDI A3 1ST GEN (2013-2017) 4DR 1.8L 40 TFSI PREMIUM (PETROL)(03.2013-02.2017)</h1>
                    <ul className="head_desc">
                        <li>Region: INDIA</li>
                        <li>Production year: 2013 - 2017</li>
                        <li>Engine type: CJSA</li>
                        <li>Body type: SEDAN</li>
                    </ul>
                </div>
                <div className="col-md-12">
                    <ul className="nav nav-tabs">
                        <li className="nav-item"><a className="nav-link active" role="tab" data-toggle="tab" href="#schema_1085344" aria-selected="true">098-010</a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active" id="schema_1085344">
                            <div className="row">
                                <div className="col-md-6 col-xs-12 col-sm-12 schema_cont_over" data-element="scheme-container" style={{ background: "#fff", zIndex: "999" }}>
                                    <div className="row">
                                        <div data-element="scheme" className="schema_cont" data-scheme-id="1085344">
                                            <img className="img-fluid" src="https://s3.ap-south-1.amazonaws.com/tofun.bucket/subparts/1725260118752127.png" />
                                            {Data.map(fig => (
                                                <a
                                                    key={fig.FigNo}
                                                    className={`fig-number ${hoveredFig === fig.FigNo ? 'hovered' : ''}`}
                                                    style={{
                                                        position: 'absolute',
                                                        left: fig.left + "%",
                                                        top: fig.top + "%",
                                                        // left: fig.left,
                                                        // top: fig.top,
                                                        background: 'yellow',
                                                        padding: '2px 5px',
                                                        borderRadius: '3px',
                                                        cursor: 'pointer'
                                                    }}
                                                    title={fig.label} // Optional: This shows the label as a tooltip
                                                    onMouseEnter={() => handleMouseEnter(fig.FigNo)}
                                                    onMouseLeave={handleMouseLeave}
                                                >
                                                    {fig.FigNo}
                                                </a>
                                            ))}
                                            {/* <a data-link-id="18705444" data-fig_no="1" href="#" title="" style={{ left: "51.8145%", top: "7.62651%", position: "absolute", background: "yellow" }}>
                                                1
                                            </a>
                                            <a data-link-id="18705445" data-fig_no="2" href="#" title="" style={{ left: "54.3347%", top: "54.526%", position: "absolute", background: "yellow" }}>
                                                2
                                            </a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xs-12 col-sm-12 products_cont" id="products_table_over">
                                    <table className=" table table-hover table-bordered products_table">
                                        <tbody><tr>
                                            <th data-ci-grid-header="fig_no">Fig. NO.</th>
                                            <th data-ci-grid-header="code">Part NO.</th>
                                            <th data-ci-grid-header="description">Description</th>
                                            <th data-ci-grid-header="remarks">Remarks</th>
                                        </tr>
                                            {Data.map((e, i) => {
                                                return <tr key={i} className={`item_1 ${hoveredFig === e.figNumber ? 'hovered' : ''}`} id="item_85702899" data-category-item="" data-links="18705444" data-scheme-link="1" style={{ background: "transparent" }} onMouseEnter={() => handleMouseEnter(e.figNumber)}
                                                    onMouseLeave={handleMouseLeave}>
                                                    <td className="level_0" data-ci-grid-cell="fig_no">
                                                        <span>{e.figNumber}</span>
                                                    </td>
                                                    <td className="level_0 category_item_pn" data-ci-grid-cell="code">
                                                        <span className="fig_no_link">
                                                            {e.part}
                                                        </span>
                                                        <a className="item_get_subscription" data-toggle="modal" role="button" data-target="#subscription"><img className="glyph" src="/css/svg/si-glyph-view.svg" style={{ height: "1.3rem" }} /></a>
                                                    </td>
                                                    <td className="level_0" data-ci-grid-cell="description">
                                                        <span>{e.decription}</span>
                                                    </td>
                                                    <td className="level_0" data-ci-grid-cell="remarks">
                                                        <span className="d-none d-lg-block">
                                                            {e.remark}         </span>
                                                    </td>
                                                </tr>
                                            })}

                                            {/* <tr id="item_85702899" data-category-item="" data-links="18705444" data-scheme-link="1" className="item_1" style={{ background: "transparent" }}>
                                                <td className="level_0" data-ci-grid-cell="fig_no">
                                                    <span>1</span>
                                                </td>
                                                <td className="level_0 category_item_pn" data-ci-grid-cell="code">
                                                    <span className="fig_no_link">
                                                        8V0*****2B
                                                    </span>
                                                    <a className="item_get_subscription" data-toggle="modal" role="button" data-target="#subscription"><img className="glyph" src="/css/svg/si-glyph-view.svg" style={{ height: "1.3rem" }} /></a>
                                                </td>
                                                <td className="level_0" data-ci-grid-cell="description">
                                                    <span>1 set brake discs withbrake pads</span>
                                                </td>
                                                <td className="level_0" data-ci-grid-cell="qty"><span>1</span></td>
                                                <td className="level_0" data-ci-grid-cell="remarks">
                                                    <span className="d-none d-lg-block">
                                                        front; Models: PR:1ZA            </span>
                                                    <span className="d-lg-none d-xl-none">
                                                        front; Models: PR:1ZA            </span>
                                                </td>
                                            </tr>
                                            <tr id="item_85702900" data-category-item="" data-links="18705445" data-scheme-link="2" className="item_2">
                                                <td className="level_0" data-ci-grid-cell="fig_no">
                                                    <span>2</span>
                                                </td>
                                                <td className="level_0 category_item_pn" data-ci-grid-cell="code">
                                                    <span className="fig_no_link">
                                                        8V0******01
                                                    </span>
                                                    <a className="item_get_subscription" data-toggle="modal" role="button" data-target="#subscription"><img className="glyph" src="/css/svg/si-glyph-view.svg" style={{ height: "1.3rem" }} /></a>
                                                </td>
                                                <td className="level_0" data-ci-grid-cell="description">
                                                    <span>1 set brake discs withbrake pads</span>
                                                </td>
                                                <td className="level_0" data-ci-grid-cell="qty"><span>1</span></td>
                                                <td className="level_0" data-ci-grid-cell="remarks">
                                                    <span className="d-none d-lg-block">
                                                        rear; Models: PR:1KE+0N1,0N4            </span>
                                                    <span className="d-lg-none d-xl-none">
                                                        rear; Models: PR:1KE+0N1,0N4            </span>
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div>
                <img classNameName='img-fluid' src='https://i.oriparts.net/scheme/0/00/01/08/53/44.png'></img>
                <a classNameName='point-style' style={{left:"51.8145%",top:"7.62651%",position:"absolute",background:"yellow"}}  >
                    1
                </a>
            </div> */}


        </>
    )
}

export default TestStyleImg